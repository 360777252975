/* eslint-disable max-lines */
import
{
  DocumentRow,
  PartialData, Student, StudentPlacement, StudentSearch, StudentRefer
} from "../../shared/models";
import
{
  Option, SearchParameter
} from "shared-lib";
import
{
  createAction, props
} from "@ngrx/store";

import
{
  Intake
} from "../../shared/models/intake";

import
{
  History
} from "../../shared/models/history";
import { CYAStudentAddressUpdate } from "../../shared/models/cya-student-address";
import { CYAStudentUpdate } from "../../shared/models/cya-student";

const studentPage = "[Student Page]";

export const fetchHaveOpenRefers = createAction("[HaveOpenRefers] Fetch", props<{ studentId: string; }>());
export const fetchHaveOpenRefersSuccess = createAction("[HaveOpenRefers] Fetch Success", props<{ HaveOpenRefers: boolean; }>());
export const fetchHaveOpenRefersError = createAction("[HaveOpenRefers] Fetch Failure", props<{ error: any; }>());
export const clearHaveOpenRefers = createAction(
  `${studentPage}  Clear [HaveOpenRefers]`
);

// LoadedStudent
export const loadStudentPage = createAction(
  `${studentPage} Load Student Page`,
  props<{ skip: number; take: number }>()
);

export const searchStudent = createAction(
  `${studentPage} Search Student`,
  props<{ student: StudentSearch; skip: number; take: number }>()
);

export const loadingStudentPageSuccessfully = createAction(
  `${studentPage} Loaded Students Successfully`,
  props<{ students: PartialData<Student> }>()
);
export const loadedStudentFailed = createAction(
  `${studentPage} loaded Student Failed`,
  props<{ Message: string }>()
);
export const loadedStudentFinish = createAction(
  `${studentPage} loaded Students Finish`
);

export const searchStudentName = createAction(
  `${studentPage} Search Students Name`,
  props<{ name: string }>()
);

export const searchStudentNameSuccessfully = createAction(
  `${studentPage}  Search Students Name Successfully`,
  props<{ studentsName: Array<string> }>()
);

// Load student by Id
export const loadStudentDetails = createAction(
  `${studentPage} Load Student Details`,
  props<{ id: string }>()
);
export const StudentDetailsLoadedSuccessfully = createAction(
  `${studentPage} Load Student Details Successfully`,
  props<{ studentDetail: Student | null }>()
);

// Clear Student
export const clearStudent = createAction(
  `${studentPage}  Clear Student Details`
);

// LoadedStudentPlacements
export const loadStudentPlacements = createAction(
  `${studentPage} Load StudentPlacements`,
  props<{ id: string; skip: number; take: number }>()
);
export const StudentPlacementsLoadedSuccessfully = createAction(
  `${studentPage} StudentPlacements Loaded Successfully`,
  props<{ studentPlacements: PartialData<StudentPlacement> }>()
);
export const loadingStudentPlacementsFailed = createAction(
  `${studentPage} Loading StudentPlacements Failed`,
  props<{ Message: string }>()
);
export const loadingStudentPlacementsFinished = createAction(
  `${studentPage} Loading StudentPlacements Finished`
);

// LoadedStudentRefers
export const loadStudentRefers = createAction(
  `${studentPage} Load StudentRefers`,
  props<{ id: string; skip: number; take: number }>()
);
export const StudentRefersLoadedSuccessfully = createAction(
  `${studentPage} StudentRefers Loaded Successfully`,
  props<{ studentRefers: PartialData<StudentRefer> }>()
);
export const loadingStudentRefersFailed = createAction(
  `${studentPage} Loading StudentRefers Failed`,
  props<{ Message: string }>()
);
export const loadingStudentRefersFinished = createAction(
  `${studentPage} Loading StudentRefers Finished`
);

// LoadedAddress
export const loadedAddressFailed = createAction(
  `${studentPage} loaded Address Failed`,
  props<{ Message: string }>()
);
export const loadedAddressFinish = createAction(
  `${studentPage} loaded Address Finish`
);

// LoadedCivilStatus
export const loadedCivilStatusFailed = createAction(
  `${studentPage} loaded CivilStatus Failed`,
  props<{ Message: string }>()
);
export const loadedCivilStatusFinish = createAction(
  `${studentPage} loaded CivilStatus Finish`
);

// Loaded Communication
export const loadedCommunicationFailed = createAction(
  `${studentPage} loaded Communication Failed`,
  props<{ Message: string }>()
);
export const loadedCommunicationFinish = createAction(
  `${studentPage} loaded Communication Finish`
);

// Loaded Id
export const loadedIdFailed = createAction(
  `${studentPage} loaded Id Failed`,
  props<{ Message: string }>()
);
export const loadedIdFinish = createAction(
  `${studentPage} loaded Id Finish`
);

// Loaded Relative
export const loadedRelativeFailed = createAction(
  `${studentPage} loaded Relative Failed`,
  props<{ Message: string }>()
);
export const loadedRelativeFinish = createAction(
  `${studentPage} loaded Relative Finish`
);

// Connected Options
export const loadConnectedOptions = createAction(
  `${studentPage} Load Connected Options`,
  props<{ fieldName: string; connected: SearchParameter[] }>()
);
export const loadConnectedOptionsSuccess = createAction(
  `${studentPage} Load Connected Options Success`,
  props<{ options: Option[]; fieldName: string }>()
);

// Options
export const loadOptions = createAction(
  `${studentPage} Load Options`,
  props<{ fieldName: string; }>()
);
export const loadOptionsSuccess = createAction(
  `${studentPage} Load Options Success`,
  props<{ options: Option[]; fieldName: string; }>()
);


// LoadIntake

export const loadIntake = createAction(
  `${studentPage} Load Intake`,

  props<{ id: string; tableName: string, skip : number , take: number }>()
);

export const loadIntakeSuccess = createAction(
  `${studentPage} Load Intake Success`,
  props<{ StudentIntake: PartialData<Intake>; tableName: string }>()
);

export const loadingFailed = createAction(
  `${studentPage} Loading Intake Failed`,
  props<{ Message: string }>()
);
export const loadingIntakeFinished = createAction(
  `${studentPage} Loading Intake Finished`
);

// LoadHistory

export const loadHistory = createAction(
  `${studentPage} Load History`,

  props<{ id: string; tableName: string, skip : number , take: number }>()
);

export const loadHistorySuccess = createAction(
  `${studentPage} Load History Success`,
  props<{ StudentHistory: PartialData<History>; tableName: string }>()
);

export const loadingHistoryFailed = createAction(
  `${studentPage} Loading History Failed`,
  props<{ Message: string }>()
);
export const loadingHistoryFinished = createAction(
  `${studentPage} Loading History Finished`
);

// Fetch Student Address
export const fetchStudentAddressUpdate = createAction(
  `${studentPage} Fetch`, props<{ id: string}>());

export const fetchStudentAddressUpdateSuccess = createAction(
  `${studentPage}  Fetch Success`,
  props<{ studentAddressUpdate: CYAStudentAddressUpdate }>());

export const fetchStudentAddressUpdateFailure = createAction(
  `${studentPage}  Fetch Failure`,
  props<{ error: string }>());

// Update student Address

export const updateStudentAddressSuccess = createAction(
  `${studentPage}  Update Success`,
  props<{ result: boolean | null }>()
);
export const updateStudentAddressFailure = createAction(
  `${studentPage}  Update Failure`,
  props<{ error: string }>()
);
export const updateStudentAddressUpdate = createAction(
  `${studentPage} Update studentAddress Update`,
  props<{ studentAddressUpdate: CYAStudentAddressUpdate }>()
);

// Clear student Address
export const clearStudentAddressUpdate = createAction(
  `${studentPage}  Clear studentAddress Update`
);

// Update Student details
export const updateStudent = createAction(
  `${studentPage} update Student`,
  props<{ student: CYAStudentUpdate; }>()
);

export const updateStudentSuccessfully = createAction(
  `${studentPage} update Student Successfully`
);

export const updateStudentFailed = createAction(
  `${studentPage} update Student failed`
);

// Load student by Id
export const loadStudentForUpdate = createAction(
  `${studentPage} Load Student For Update`,
  props<{ id: string; }>()
);
export const loadStudentForUpdateSuccessfully = createAction(
  `${studentPage} Load Student For Update Successfully`,
  props<{ studentForUpdate: CYAStudentUpdate | null; }>()
);

// Load Documents
export const loadDocuments = createAction(
  `${studentPage} Load Documents`,
  props<{ id: string; skip: number; take: number; }>()
);
export const loadDocumentsSuccessfully = createAction(
  `${studentPage} Documents Loaded Successfully`,
  props<{ documents: PartialData<DocumentRow>; }>()
);
export const loadDocumentsFailed = createAction(
  `${studentPage} Loading Documents Failed`,
  props<{ Message: string; }>()
);
export const loadDocumentsFinished = createAction(
  `${studentPage} Loading Documents Finished`
);
